import { useAuth0 } from '@auth0/auth0-react';
import Loading from '~/components/Loading';

export function OrganizationLogin() {
  const { user, isLoading, loginWithRedirect } = useAuth0();

  // if unauthenticated, send user to login
  // TODO: Store a cookie for what page they wanted to visit and redirect to that cookie once logged in
  if (!user && !isLoading) {
    loginWithRedirect();
  }
  return <Loading />;
}
