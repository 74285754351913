import { useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';

import { initAmplitude, setAmplitudeUserId } from '~/services/amplitude/amplitude';
import { OrganizationProvider } from '~/providers/OrganizationProvider';
import { useApolloClientSetup } from '~/services/graphql';
import Loading from '~/components/Loading';
import { AppBarContext } from '~/components/AppBar/AppBarContext';
import { ProfileProvider } from '~/providers/ProfileProvider';
import { DataHubProvider } from '~/providers/DataHubProvider';
import ToastContext from '~/providers/ToastProvider';

import Auth0ProviderWithHistory from './auth/auth0-provider-with-history';
import { OrganizationLogin } from './auth/OrganizationLogin';
import { AppRoutes } from './routes';
import { useIntercom } from 'react-use-intercom';

initAmplitude();

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login/:organization" element={<OrganizationLogin />} />
        <Route path="*" element={<AuthenticatedProviders />} />
      </Routes>
    </BrowserRouter>
  );
}

function AuthenticatedProviders() {
  return (
    <Auth0ProviderWithHistory>
      <ApolloClientProvider>
        <ToastContext>
          <ProfileProvider>
            <OrganizationProvider>
              <DataHubProvider>
                <AppBarContext>
                  <AppRoutes />
                </AppBarContext>
              </DataHubProvider>
            </OrganizationProvider>
          </ProfileProvider>
        </ToastContext>
      </ApolloClientProvider>
    </Auth0ProviderWithHistory>
  );
}

// this provider is for apollo client that stores the auth token, also acts as a route protector
const ApolloClientProvider: React.FC<any> = ({ children }) => {
  const { apolloClient, loading } = useApolloClientSetup();
  const { user, isLoading, loginWithRedirect, error } = useAuth0();
  const { update } = useIntercom();

  // if unauthenticated, send user to login
  // TODO: Store a cookie for what page they wanted to visit and redirect to that cookie once logged in
  if (!user && !isLoading) {
    loginWithRedirect();
  }
  if (error) {
    console.error(error);
  }
  useEffect(() => {
    if (user) {
      setAmplitudeUserId(user.email!);
      update({ email: user.email! });
    }
  }, [user, isLoading]);

  // while loading, do not let protected routes show, or else they're going
  // to be making unauthorized requests while apolloClient is initializing
  if (loading || isLoading || !apolloClient) {
    return <Loading />;
  }
  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
};

export default App;
