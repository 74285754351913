import React, { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import ExitToApp from '@mui/icons-material/ExitToApp';
import ExpandMore from '@mui/icons-material/ExpandMore';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Link from '@mui/material/Link';
import useMediaQuery from '@mui/material/useMediaQuery';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import useTheme from '@mui/material/styles/useTheme';
import Select from 'react-select';

import Icon from '~/assets/images/icon.svg';
import NavButton from '~/components/AppBar/NavButton';
import NavMenu from '~/components/AppBar/NavMenu';
import { MENU_ITEMS } from '~/components/AppBar/types';
import { ROUTES } from '~/index/routes';
import { useProfileContext } from '~/providers/ProfileProvider';
import { AMPLITUDE_EVENT, sendAmplitudeData } from '~/services/amplitude/amplitude';
import { makeStyles } from '~/styles/global';
import { IOptionType } from '~/types/global/global.types';

import ProfileImageIcon from './ProfileImageIcon';
import { alpha } from '@mui/material';

export default function Navigation() {
  const theme = useTheme();
  const showLabelText = useMediaQuery(theme.breakpoints.up('md'));
  const { classes } = useStyles();
  const { logout } = useAuth0();
  const { profile } = useProfileContext();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [dataHubValue, setDataHubValue] = useState<null | IOptionType>({
    value: '1',
    label: 'Data Hub #1',
  } as IOptionType);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleLogout = () => {
    sendAmplitudeData(AMPLITUDE_EVENT.LOGOUT);
    localStorage.clear(); // clear auth0_org_id
    logout({ returnTo: window.location.origin });
  };

  return (
    <div className={classes.root}>
      <Box height="30px">
        <Box position="fixed" width="100%" zIndex={1000}>
          <AppBar position="relative" elevation={0}>
            <Toolbar className={classes.appBar}>
              <Box flexGrow={1} display="flex" alignItems="center">
                <Box mr={2}>
                  <Typography variant="h6">
                    <Link component={RouterLink} to={ROUTES.ANALYSIS.ROOT} color="primary">
                      <img src={Icon} alt="Adaptive Pulse" />
                    </Link>
                  </Typography>
                </Box>
                {MENU_ITEMS.map(menuItem => (
                  <NavButton
                    showLabel={showLabelText}
                    key={menuItem.label}
                    route={menuItem.route}
                    label={menuItem.label}
                    icon={<menuItem.icon />}
                    disabled={menuItem.disabled}
                    // eslint-disable-next-line react/no-children-prop
                    children={menuItem.children}
                  />
                ))}
              </Box>
              {profile && (
                <>
                  <Box display="flex" flexDirection="row" alignItems="center">
                    <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
                      <Button
                        data-cy="profile-menu"
                        size="small"
                        startIcon={<ProfileImageIcon profile={profile} />}
                        endIcon={<ExpandMore />}
                        onClick={handleMenu}
                        color="inherit"
                      >
                        <Box className={classes.profileName}>
                          <strong>{profile ? profile.fullName : ''}</strong>
                        </Box>
                      </Button>
                    </ClickAwayListener>
                  </Box>
                  <NavMenu
                    anchorEl={anchorEl}
                    menuItems={[
                      {
                        onClick: () => handleLogout(),
                        label: 'Logout',
                        startIcon: ExitToApp,
                      },
                    ]}
                    placement="bottom-end"
                  />
                </>
              )}
            </Toolbar>
          </AppBar>
        </Box>
      </Box>
    </div>
  );
}

const useStyles = makeStyles()(theme => ({
  root: {
    flexGrow: 1,
  },
  title: {
    flexGrow: 1,
    color: theme.palette.primary.main,
  },
  appBar: {
    backgroundColor: theme.palette.mode === 'light' ? 'white' : undefined,
    minHeight: '55px',
    color: theme.palette.text.primary,
  },
  menu: {
    marginTop: theme.spacing(1),
  },
  profileName: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
}));
