/**
 * Asynchronously loads the component for DataHubPage
 */

import { lazyLoad } from '~/helpers/loadable';

export const DataHubPage = lazyLoad(
  () => import('./index'),
  module => module.DataHubPage,
);
