import { isProduction } from '~/helpers/envCheck';

// Internal
export const DEFAULT_PROFILE_IMAGE_URL =
  'https://s3.ca-central-1.amazonaws.com/app.adaptivepulse.images/profiles/defaultimageprofile/image.png';

// S3
export const S3_IMAGES_ENDPOINT_DEV =
  'https://s3.ca-central-1.amazonaws.com/staging-app.adaptivepulse.images';
export const S3_IMAGES_ENDPOINT_PROD =
  'https://s3.ca-central-1.amazonaws.com/app.adaptivepulse.images';
export const S3_IMAGES_ENDPOINT = isProduction() ? S3_IMAGES_ENDPOINT_PROD : S3_IMAGES_ENDPOINT_DEV;

// Amplitude
export const AMPLITUDE_DEVELOPMENT_KEY = '5e199a816b7fb85bcf0d57ec0c93e96b';
export const AMPLITUDE_STAGING_KEY = 'de4b7a1f779d45ba7418140a6aa9f34c';
export const AMPLITUDE_PRODUCTION_KEY = '3dbac6601efd581c536eecb8d1f8d26e';
