import { gql } from '@apollo/client';

export const MY_ORGANIZATION = gql`
  query {
    myOrganization {
      id
      name
      auth0_org_id
      subdomain
      stripe_id
    }
  }
`;

export const UPDATE_ORGANIZATION = gql`
  mutation UpdateOrganization($name: String!) {
    updateOrganization(data: { name: $name }) {
      id
    }
  }
`;

export const CREATE_SUBDOMAIN = gql`
  mutation CreateSubdomain($subdomain: String!) {
    createSubdomain(subdomain: $subdomain) {
      id
    }
  }
`;
