/**
 * Asynchronously loads the component for DataHubDetailsPage
 */

import { lazyLoad } from '~/helpers/loadable';

export const DataHubDetailsPage = lazyLoad(
  () => import('./index'),
  module => module.DataHubDetailsPage,
);
