/**
 * Asynchronously loads the component for ConnectPage
 */

import { lazyLoad } from '~/helpers/loadable';

export const ConnectPage = lazyLoad(
  () => import('./index'),
  module => module.ConnectPage,
);
