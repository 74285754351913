import { useState } from 'react';
import { useLocation } from 'react-router-dom';

import { BlankRoutes } from '~/index/routes';
import { makeStyles } from '~/styles/global';
import { createGenericContext } from '~/helpers/createGenericContext';

import AppBar from './Navigation';

interface IAppBarContextProps {
  hidden: boolean;
  setHidden: (hidden: boolean) => unknown;
}

export const [useDrawer, AppBarContextProvider] = createGenericContext<IAppBarContextProps>();

export function AppBarContext({ children }) {
  const { classes } = useStyles();
  const [hidden, setHidden] = useState(false);
  const location = useLocation();

  const context: IAppBarContextProps = {
    hidden,
    setHidden,
  };

  const shouldHide = BlankRoutes.includes(location.pathname) || hidden;

  return (
    <AppBarContextProvider value={context}>
      <>
        {!shouldHide && <AppBar />}
        <main className={classes.content}>{children}</main>
      </>
    </AppBarContextProvider>
  );
}

const useStyles = makeStyles()(theme => ({
  content: {
    marginTop: '15px',
    flexGrow: 1,
    padding: theme.spacing(5),
  },
}));
