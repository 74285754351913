import React, { useCallback, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useIntercom } from 'react-use-intercom';
import { ApolloError, useApolloClient, useLazyQuery, useMutation } from '@apollo/client';

import { IUserProfile } from '~/types/profile/profile.types';
import { createGenericContext } from '~/helpers/createGenericContext';
import {
  CREATE_CURRENT_USER_PROFILE,
  GET_CURRENT_USER_PROFILE,
} from '~/services/graphql/profile/userProfile';
import { CurrentUserProfileResponse } from '~/types/profile/profile.types';
import logger from '~/helpers/logger';
import { ProfileImg } from '~/types/profile/profile.types';

interface IProfileContext {
  loading: boolean;
  profile: IUserProfile | undefined;
  error?: ApolloError;
  createProfile: (props: ICreateProfileProps) => Promise<void>;
  refetchProfile: () => void;
}

export interface IUpdateProfileProps {
  fullName?: string;
  email?: string;
  industry?: string;
  jobRole?: string;
  profileImg?: ProfileImg;
  organizationSizeAtSignup?: string;
}

export interface ICreateProfileProps {
  fullName?: string;
  email?: string;
}

export const [useProfileContext, ProfileContextProvider] = createGenericContext<IProfileContext>();

export const ProfileProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const client = useApolloClient();
  const auth0 = useAuth0();
  const { boot } = useIntercom();

  const [loadProfile, { loading, data, refetch, error }] = useLazyQuery<CurrentUserProfileResponse>(
    GET_CURRENT_USER_PROFILE,
    { client },
  );
  const [createProfileMutation, createProfileResponse] = useMutation(CREATE_CURRENT_USER_PROFILE);

  useEffect(() => {
    loadProfile();
  }, [loadProfile]);

  const refetchProfile = () => {
    if (refetch) {
      refetch();
    } else {
      loadProfile();
    }
  };

  const createProfile = useCallback(
    async (props: ICreateProfileProps) => {
      const response = await createProfileMutation({ variables: props });

      if (response.errors) {
        logger.error('ProfileProvider::createProfile Error occurred in Create Profile', {
          props,
          errors: response.errors,
        });
        return;
      }

      refetch();
    },
    [createProfileMutation],
  );

  useEffect(() => {
    if (
      !loading &&
      data &&
      !data.me.email &&
      !auth0.isLoading &&
      !createProfileResponse.loading &&
      !createProfileResponse.data
    ) {
      createProfile({
        fullName: auth0.user?.name,
        email: auth0.user?.email,
      });
    }
  }, [loading, data, auth0, createProfileResponse.loading]);

  useEffect(() => {
    if (data?.me) {
      boot({
        name: data?.me.fullName,
        email: data?.me.email,
      });
    }
  }, [data]);

  const context: IProfileContext = {
    loading: createProfileResponse.loading || loading,
    profile:
      data?.me ||
      ({
        id: '',
        organization_id: '',
        email: auth0.user?.email,
        fullName: auth0.user?.name,
      } as IUserProfile),
    error: error,
    createProfile,
    refetchProfile,
  };
  return <ProfileContextProvider value={context}>{children}</ProfileContextProvider>;
};
