import { gql } from '@apollo/client';

export const GET_ORGANIZATION_DATAHUBS = gql`
  query {
    organizationDataHubs {
      id
      name
      cloud_provider
      connections {
        id
        name
        type
      }
      customers {
        id
      }
      metrics {
        id
      }
    }
  }
`;

export const GET_DATAHUB = gql`
  query dataHub($id: String!) {
    dataHub(id: $id) {
      id
      name
      cloud_provider
      connections {
        id
        createdAt
        name
        type
      }
      customers {
        id
        name
      }
      metrics {
        id
        name
      }
    }
  }
`;

export const CREATE_DATAHUB = gql`
  mutation createDataHub($name: String!) {
    createDataHub(input: { name: $name }) {
      id
    }
  }
`;
