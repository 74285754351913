/**
 * Asynchronously loads the component for Customer360Page
 */

import { lazyLoad } from '~/helpers/loadable';

export const Customer360Page = lazyLoad(
  () => import('./index'),
  module => module.Customer360Page,
);
