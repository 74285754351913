import React, { useCallback, useEffect } from 'react';
import { ApolloError, useApolloClient, useLazyQuery, useMutation } from '@apollo/client';

import { createGenericContext } from '~/helpers/createGenericContext';
import { CREATE_DATAHUB, GET_ORGANIZATION_DATAHUBS } from '~/services/graphql/datahub/datahub';
import {
  OrganizationDataHubsResponse,
  IDataHub,
  ICreateDataHubProps,
} from '~/types/datahub/datahub.types';
import logger from '~/helpers/logger';
import { CREATE_CONNECTION } from '~/services/graphql/connection/connection';
import { ICreateConnectionProps } from '~/types/connection/connection.types';
import { useToast } from './ToastProvider';

interface IDataHubContext {
  loading: boolean;
  dataHubs: IDataHub[];
  getDataHub: (id) => IDataHub | undefined;
  createDataHub: (props: ICreateDataHubProps) => Promise<void>;
  createConnection: (props: ICreateConnectionProps) => Promise<boolean>;
  error?: ApolloError;
}

export const [useDataHubContext, DataHubContextProvider] = createGenericContext<IDataHubContext>();

export const DataHubProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const client = useApolloClient();
  const toast = useToast();

  const [loadDataHubs, { loading, data, refetch, error }] =
    useLazyQuery<OrganizationDataHubsResponse>(GET_ORGANIZATION_DATAHUBS, { client });

  const [createDataHubMutation, createDataHubResponse] = useMutation(CREATE_DATAHUB);
  const [createConnectionMutation, createConnectionResponse] = useMutation(CREATE_CONNECTION);

  const createDataHub = useCallback(
    async (props: ICreateDataHubProps) => {
      const response = await createDataHubMutation({ variables: props });

      if (response.errors) {
        logger.error('DataHubProvider::createDataHub Error occurred in Create Data Hub', {
          props,
          errors: response.errors,
        });
        return;
      }

      refetch();
    },
    [createDataHubMutation],
  );

  const createConnection = useCallback(
    async (props: ICreateConnectionProps) => {
      const response = await createConnectionMutation({ variables: { input: props } });

      if (response.errors) {
        logger.error('DataHubProvider::createConnection Error occurred in Create Connection', {
          props,
          errors: response.errors,
        });

        toast.showError('Error creating connection... try again or contact support');

        return false;
      }

      toast.showSuccess('Successfully created connection!');

      refetch();

      return true;
    },
    [createConnectionMutation],
  );

  useEffect(() => {
    loadDataHubs();
  }, [loadDataHubs]);

  const getDataHub = id => {
    return data?.organizationDataHubs.filter(x => x.id === id)[0];
  };

  const context: IDataHubContext = {
    loading: loading,
    dataHubs: data?.organizationDataHubs || [],
    getDataHub: getDataHub,
    createDataHub: createDataHub,
    createConnection: createConnection,
    error: error,
  };
  return <DataHubContextProvider value={context}>{children}</DataHubContextProvider>;
};
