import { gql } from '@apollo/client';

import { IConnection } from '~/types/connection/connection.types';

export interface IMUTATION_ADD_DEMO_DATA_RESPONSE {
  addDemoData: IConnection;
}

export interface IMUTATION_TEST_CONNECTION_RESPONSE {
  testConnection: Boolean;
}

export const GET_DATAHUB_CONNECTIONS = gql`
  query {
    dataHubConnections {
      id
      name
    }
  }
`;

export const CREATE_CONNECTION = gql`
  mutation createConnection($input: CreateConnectionDto!) {
    createConnection(input: $input) {
      id
    }
  }
`;

export const MUTATION_ADD_DEMO_DATA = gql`
  mutation {
    addDemoData {
      id
      createdAt
    }
  }
`;

export const MUTATION_TEST_CONNECTION = gql`
  mutation ($input: ConnectionDto!) {
    testConnection(input: $input)
  }
`;
