import React from 'react';
import Icon from '~/assets/images/icon.svg';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { Typography } from '@mui/material';

interface Props {
  size?: number;
  message?: string;
}

export default function Loading({ size = 40, message }: Props) {
  return (
    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
      <Box position="relative" display="flex" alignItems="center" justifyContent="center" p={2}>
        <img
          style={{
            width: size * 0.5,
            position: 'absolute',
          }}
          src={Icon}
          alt={'Adaptive Pulse'}
        />
        <CircularProgress size={size} color="primary" />
      </Box>
      <Typography variant="subtitle2">{message}</Typography>
    </Box>
  );
}
