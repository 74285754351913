import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Popper, { PopperProps } from '@mui/material/Popper';
import { SvgIconTypeMap } from '@mui/material/SvgIcon';
import Typography from '@mui/material/Typography';

import { OverridableComponent } from '@mui/material/OverridableComponent';
import { withStyles } from 'tss-react/mui';
import { alpha } from '@mui/material';

interface Props {
  anchorEl: any;
  menuItems: {
    selected?: boolean;
    onClick: () => void;
    label: string;
    startIcon?: OverridableComponent<SvgIconTypeMap<{}, 'svg'>>;
  }[];
  placement?: PopperProps['placement'];
}

const MenuItemBtn = withStyles(MenuItem, theme => ({
  root: {
    padding: '10px 20px',
    minWidth: '200px',
  },
  selected: {
    backgroundColor:
      theme.palette.mode === 'light'
        ? `${theme.palette.primary.light} !important`
        : alpha(theme.palette.primary.main, 0.7) + ' !important',
    color:
      theme.palette.mode === 'light' ? theme.palette.primary.dark : theme.palette.background.paper,
  },
}));

export default function NavMenu({ anchorEl, menuItems, placement }: Props) {
  return (
    <Popper
      style={{ zIndex: 1000 }}
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      placement={placement ?? 'bottom-start'}
    >
      <Paper>
        <Box>
          {menuItems.map(item => (
            <MenuItemBtn key={item.label} onClick={item.onClick} selected={item.selected}>
              <Box display="flex" flexDirection="row" alignItems="center">
                {item.startIcon && <item.startIcon fontSize="small" />}
                <Box ml={1}>
                  <Typography variant="subtitle2">{item.label}</Typography>
                </Box>
              </Box>
            </MenuItemBtn>
          ))}
        </Box>
      </Paper>
    </Popper>
  );
}
