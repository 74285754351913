import {
  AccountTreeOutlined,
  HomeRounded,
  SensorOccupied,
  BarChartOutlined,
} from '@mui/icons-material';

import { ROUTES } from '~/index/routes';

export const MENU_ITEMS = [
  {
    label: 'Analysis',
    icon: BarChartOutlined,
    route: `${ROUTES.ANALYSIS.ROOT}`,
    disabled: false,
    children: [],
  },
  // {
  //   label: 'Data Hubs',
  //   icon: HomeRounded,
  //   route: `${ROUTES.DATAHUB.ROOT}`,
  // },
  // {
  //   label: 'Customer 360',
  //   icon: SensorOccupied,
  //   route: ROUTES.CUSTOMER_360,
  // },
  // {
  //   label: 'Workflows (Coming Soon)',
  //   icon: AccountTreeOutlined,
  //   route: ROUTES.WORKFLOWS,
  //   disabled: true,
  //   children: [],
  // },
];
