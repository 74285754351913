import { useState } from 'react';
import Box from '@mui/material/Box';

import { DEFAULT_PROFILE_IMAGE_URL, S3_IMAGES_ENDPOINT } from '~/constants/constants';
import { IUserProfile } from '~/types/profile/profile.types';

const cacheBuster = '?' + new Date().getTime();

interface Props {
  profile: IUserProfile;
}

export default function ProfileImageIcon({ profile }: Props) {
  // Default image from @mui/icons-material/AccountCircle
  const [selectedImage, setSelectedImage] = useState<string>(DEFAULT_PROFILE_IMAGE_URL);

  // const loadingProfileImage = new Image();

  // loadingProfileImage.src = `${S3_IMAGES_ENDPOINT}/profiles/${profile?.id}/image?` + cacheBuster;

  // loadingProfileImage.onload = () => {
  //   setSelectedImage(loadingProfileImage.src);
  // };

  return (
    <Box width="22px" height="22px">
      <img
        width="100%"
        height="100%"
        style={{
          borderRadius: '50%',
          objectFit: 'cover',
        }}
        src={selectedImage}
      />
    </Box>
  );
}
