/**
 * Asynchronously loads the component for Customer360DetailsPage
 */

import { lazyLoad } from '~/helpers/loadable';

export const Customer360DetailsPage = lazyLoad(
  () => import('./index'),
  module => module.Customer360DetailsPage,
);
