import { useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';
import { OnboardingPage } from '~/pages/Onboarding/Loadable';
import { DataHubPage } from '~/pages/DataHub/Loadable';
import { DataHubDetailsPage } from '~/pages/DataHub/Details/Loadable';
import { Customer360Page } from '~/pages/Customer360/Loadable';
import { WorkflowsPage } from '~/pages/Workflows/Loadable';
import { ConnectPage } from '~/pages/DataHub/Details/components/Connect/Loadable';
import { Customer360DetailsPage } from '~/pages/Customer360/Details/Loadable';
import { AnalysisPage } from '~/pages/Analysis/Loadable';

export const ROUTES = {
  ANALYSIS: {
    ROOT: '/analysis',
  },
  DATAHUB: {
    ROOT: '/datahub',
    EXPLORE: 'explore',
    ANALYZE: 'analyze',
    SETTINGS: 'settings',
    CONNECT: 'connect',
  },
  DATASOURCES: {
    ROOT: '/datasources',
  },
  ONBOARDING: {
    ROOT: '/onboarding',
    ORGANIZATION: 'organization',
    PROFILE: 'profile',
    HOW_TO: 'how-to',
    CONNECT: 'connect',
  },
  CUSTOMER_360: '/360',
  WORKFLOWS: '/workflows',
};

// Add routes here that we don't want to show AppBar and drawer on.
export const BlankRoutes: string[] = [
  ROUTES.ONBOARDING.ROOT,
  `${ROUTES.ONBOARDING.ROOT}/${ROUTES.ONBOARDING.ORGANIZATION}`,
  `${ROUTES.ONBOARDING.ROOT}/${ROUTES.ONBOARDING.PROFILE}`,
  `${ROUTES.ONBOARDING.ROOT}/${ROUTES.ONBOARDING.HOW_TO}`,
  `${ROUTES.ONBOARDING.ROOT}/${ROUTES.ONBOARDING.CONNECT}`,
];

export function AppRoutes() {
  const location = useLocation();

  const { update } = useIntercom();

  useEffect(() => {
    update();
  }, [location]);

  return (
    <Routes>
      <Route path="/" element={<AnalysisPage />} />
      <Route path={`${ROUTES.ONBOARDING.ROOT}/*`} element={<OnboardingPage />} />
      <Route path={ROUTES.DATAHUB.ROOT} element={<DataHubPage />}></Route>
      <Route
        path={`${ROUTES.DATAHUB.ROOT}/:dataHubID/${ROUTES.DATAHUB.CONNECT}`}
        element={<ConnectPage />}
      ></Route>
      <Route path={`${ROUTES.DATAHUB.ROOT}/:id`} element={<DataHubDetailsPage />}></Route>
      <Route path={ROUTES.CUSTOMER_360} element={<Customer360Page />} />
      <Route
        path={`${ROUTES.CUSTOMER_360}/:dataHubID/:customerID`}
        element={<Customer360DetailsPage />}
      />
      <Route path={ROUTES.WORKFLOWS} element={<WorkflowsPage />} />
      <Route path={ROUTES.ANALYSIS.ROOT} element={<AnalysisPage />} />
    </Routes>
  );
}
