import { Auth0Provider, Auth0ProviderOptions } from '@auth0/auth0-react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { FC } from 'react';

type ParamTypes = {
  organization?: string;
};

const Auth0ProviderWithHistory: FC<{ children: JSX.Element }> = ({ children }) => {
  const domain = import.meta.env.VITE_APP_AUTH0_DOMAIN!;
  const clientId = import.meta.env.VITE_APP_AUTH0_CLIENT_ID!;
  const audience = import.meta.env.VITE_APP_AUTH0_AUDIENCE!;

  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const params = useParams<ParamTypes>();
  const navigate = useNavigate();

  const onRedirectCallback: Auth0ProviderOptions['onRedirectCallback'] = appState => {
    navigate(appState?.returnTo || window.location.pathname);
  };

  const organization =
    params.organization || query.get('organization') || localStorage.getItem('organization');

  if (organization) {
    // TODO: Save to some localStorage array so we can support switching organizations
    localStorage.setItem('organization', organization);
  }

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      audience={audience}
      cacheLocation={
        import.meta.env.VITE_APP_NODE_ENV === 'development' ? 'localstorage' : undefined
      }
      redirectUri={window.location.origin}
      onRedirectCallback={onRedirectCallback}
      organization={organization || undefined}
      invitation={query.get('invitation') ?? undefined}
    >
      {children}
    </Auth0Provider>
  );
};

export default Auth0ProviderWithHistory;
