import { gql } from '@apollo/client';

export const GET_CURRENT_USER_PROFILE = gql`
  query getProfile {
    me {
      id
      fullName
      email
      organization {
        id
      }
    }
  }
`;

export const CREATE_CURRENT_USER_PROFILE = gql`
  mutation createProfile($fullName: String, $email: String) {
    createProfile(data: { fullName: $fullName, email: $email }) {
      id
      fullName
      email
      organization {
        id
      }
    }
  }
`;

export const ENABLE_DEMO_ORG = gql`
  mutation enableDemoOrg {
    enableDemoOrg {
      userUUID
      organizationUUID
      initialOrganizationUUID
    }
  }
`;

export const DISABLE_DEMO_ORG = gql`
  mutation disableDemoOrg {
    disableDemoOrg {
      userUUID
      organizationUUID
      initialOrganizationUUID
    }
  }
`;
