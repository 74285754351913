/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-unused-expressions */

const isDev = import.meta.env.VITE_APP_NODE_ENV === 'development';

const logger = {
  error(msg: string, ...params) {
    isDev && console.error(msg, ...(params ?? []));
  },
  debug(msg: string, ...params) {
    isDev && console.debug(msg, ...(params ?? []));
  },
  info(msg: string, ...params) {
    // eslint-disable-next-line
    isDev && console.info(msg, ...(params ?? []));
  },
};

export default logger;
