import { useEffect, useState } from 'react';
import {
  ApolloClient,
  createHttpLink,
  from,
  InMemoryCache,
  NormalizedCacheObject,
  Reference,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { useAuth0 } from '@auth0/auth0-react';
import { onError } from '@apollo/client/link/error';
import { ReadFieldFunction } from '@apollo/client/cache/core/types/common';

function CustomerOffsetFromCursor(
  items: Reference[],
  cursor: string,
  readField: ReadFieldFunction,
) {
  // Search from the back of the list because the cursor we're
  // looking for is typically the ID of the last item.
  for (let i = items.length - 1; i >= 0; --i) {
    const item = items[i];
    const customerId = readField('customerId', item);
    if (customerId === cursor) {
      return i + 1;
    }
  }
  // Report that the cursor could not be found.
  return -1;
}

export const cache = new InMemoryCache({
  typePolicies: {
    Profile: {
      // If one of the keyFields is an object with fields of its own, you can
      // include those nested keyFields by using a nested array of strings:
      keyFields: ['id'],
    },
    Organization: {
      keyFields: ['id'],
    },
    Customer: {
      keyFields: ['id'],
    },
    Query: {
      fields: {
        pendingInvites: {
          merge(existing, incoming) {
            return incoming;
          },
        },
        alertsPaginated: {
          keyArgs: false,
        },
        customers: {
          keyArgs: false,
          merge(existing: any[], incoming, { args, readField }) {
            if (!existing) {
              return incoming;
            }
            const cursor = args?.lastCustomerId;

            const merged = existing ? existing.slice(0) : [];

            let offset = CustomerOffsetFromCursor(merged, cursor, readField);
            // If we couldn't find the cursor, default to appending to
            // the end of the list, so we don't lose any data.
            if (offset < 0) offset = merged.length;
            // Now that we have a reliable offset, the rest of this logic
            // is the same as in offsetLimitPagination.
            for (let i = 0; i < incoming.length; ++i) {
              merged[offset + i] = incoming[i];
            }
            return merged;
          },
        },
      },
    },
  },
});

function getClient(token?: string) {
  const httpLink = createHttpLink({
    uri: import.meta.env.VITE_APP_API_CORE_URL,
  });

  const httpAuthLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        authorization: `Bearer ${token}`,
      },
    };
  });

  const errorLink = onError(({ graphQLErrors, networkError, forward, operation }) => {
    if (graphQLErrors)
      graphQLErrors.forEach(({ message, locations, path }) =>
        console.error(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
        ),
      );
    // FIXME: Add network error screen and redirect from here.
    if (networkError) console.log(`[Network error]: ${networkError}`);
    return forward(operation);
  });

  return new ApolloClient({
    link: from([errorLink, httpAuthLink.concat(httpLink)]),
    defaultOptions: {
      mutate: {
        errorPolicy: 'all',
      },
      query: { errorPolicy: 'all' },
    },
    cache: cache,
  });
}

export function useApolloClientSetup() {
  const [apolloClient, setApolloClient] = useState<ApolloClient<NormalizedCacheObject>>();
  const [loading, setLoading] = useState(true);

  const { getAccessTokenSilently, user } = useAuth0();

  useEffect(() => {
    (async () => {
      if (!user) {
        return;
      }
      const t = await getAccessTokenSilently();

      setApolloClient(getClient(t));
      setLoading(false);
    })();
  }, [user]);

  return { apolloClient, loading };
}
