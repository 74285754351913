/**
 * Asynchronously loads the component for AnalysisPage
 */

import { lazyLoad } from '~/helpers/loadable';

export const AnalysisPage = lazyLoad(
  () => import('./index'),
  module => module.AnalysisPage,
);
