import * as React from 'react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ApolloError, useLazyQuery, useMutation } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';

import { createGenericContext } from '~/helpers/createGenericContext';
import { IOrganization } from '~/types/organization/organization.types';
import { MY_ORGANIZATION, UPDATE_ORGANIZATION } from '~/services/graphql/organization/organization';
import { MyOrganizationResponse } from '~/types/organization/organization.types';
import { ROUTES } from '~/index/routes';
import { MutationResponse } from '~/types/global/global.types';

interface IOrganizationContext {
  loading: boolean;
  error?: ApolloError;
  organization?: IOrganization;
  updateOrganization: (props: IUpdateOrganizationProps) => Promise<MutationResponse>;
  refetchOrganization: () => Promise<IOrganization | undefined>;
  isDemoOrg: boolean;
}

export interface IUpdateOrganizationProps {
  name?: string;
}

export const [useOrganizationContext, OrganizationContextProvider] =
  createGenericContext<IOrganizationContext>();

export const OrganizationProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { isLoading, isAuthenticated } = useAuth0();
  const navigate = useNavigate();

  const [loadOrganization, { loading, data, refetch, error }] =
    useLazyQuery<MyOrganizationResponse>(MY_ORGANIZATION);
  const [updateOrganizationMutation] = useMutation(UPDATE_ORGANIZATION);

  useEffect(() => {
    if (!isLoading && isAuthenticated) {
      loadOrganization();
    }
  }, [isLoading, isAuthenticated, loadOrganization]);

  useEffect(() => {
    if (data?.myOrganization === null) {
      if (
        import.meta.env.VITE_APP_NODE_ENV === 'development' &&
        window.location.pathname === '/scratch'!
      ) {
        // NOTE: ignoring the redirect running in cypress,
        //       this is so we can access scratch page and
        //       delete test profile
        return;
      }
      navigate(ROUTES.ONBOARDING.ROOT);
    }
  }, [data, history]);

  const refetchOrganization = async () => {
    const organization = await (refetch ? refetch() : loadOrganization());
    return organization.data?.myOrganization;
  };

  const updateOrganization = async ({ name }: IUpdateOrganizationProps) => {
    const newName = name ?? data?.myOrganization.name;

    const response = await updateOrganizationMutation({
      variables: {
        name: newName,
      },
    });
    // @ts-ignore
    if (response.errors) {
      // @ts-ignore
      return { success: false, error: response.errors[0].message };
    } else {
      refetchOrganization();

      return { success: true };
    }
  };

  const context: IOrganizationContext = {
    loading: loading,
    error: error,
    organization: data?.myOrganization,
    updateOrganization,
    refetchOrganization,
    isDemoOrg: import.meta.env.VITE_APP_DEMO_ORG_UUID === data?.myOrganization?.id,
  };

  return <OrganizationContextProvider value={context}>{children}</OrganizationContextProvider>;
};
