import type { PaletteMode } from '@mui/material';
import { grey } from '@mui/material/colors';
import createTheme from '@mui/material/styles/createTheme';

export default function getTheme(mode: PaletteMode) {
  return createTheme({
    palette: {
      mode,
      background:
        mode === 'light'
          ? {
              default: '#f6f8fa',
            }
          : {
              default: '#1c1b1a',
            },
      text:
        mode === 'light'
          ? {
              primary: '#434343',
              secondary: '#606060',
              disabled: grey[400],
            }
          : {
              primary: '#ebebeb',
              secondary: '#bababa',
              disabled: grey[600],
            },
      // Blue
      primary:
        mode === 'light'
          ? {
              main: '#939DFF',
              light: '#DBDEFF',
              dark: '#4A56CF',
              contrastText: '#f9f9f9',
            }
          : {
              main: '#9fbbfc',
              light: '#d4e6ff',
              dark: '#5c7eed',
              contrastText: '#f2f8fa',
            },
      // Yellow
      secondary:
        mode === 'light'
          ? {
              main: '#FFF593',
              light: '#fff59e',
              dark: '#febf92',
              contrastText: '#121212',
            }
          : {
              main: '#edeb61',
              light: '#f5f2a4',
              dark: '#e3cb54',
              contrastText: '#1b1c06',
            },
      score: {
        highRisk: '#fee0e8',
        highRiskContrast: '#ea4435',
        moderateRisk: '#fff2e9',
        moderateRiskContrast: '#ff9341',
        lowRisk: '#e3f8f0',
        lowRiskContrast: '#46d39a',
      },
    },
    typography: {
      allVariants: {
        fontFamily: "'Roboto'",
      },
      fontFamily: "'Roboto'",
    },
    components: {
      MuiTabs: {
        styleOverrides: {
          indicator: {
            background: '#4A56CF',
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            '&.Mui-selected': {
              color: '#4A56CF',
            },
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          root: { '&:hover': { cursor: 'pointer' } },
        },
      },
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            fontSize: '12px',
            padding: '10px',
          },
        },
      },
      MuiLink: {
        styleOverrides: {
          root: {
            '&:hover': { cursor: 'pointer' },
            color: '#4A56CF',
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: 'none',
            fontWeight: 'bold',
            letterSpacing: '1.2px',
          },
        },
      },
    },
  });
}

declare module '@mui/material/styles' {
  interface Palette {
    score: {
      highRisk: string;
      highRiskContrast: string;
      moderateRisk: string;
      moderateRiskContrast: string;
      lowRisk: string;
      lowRiskContrast: string;
    };
  }

  interface PaletteOptions {
    score: {
      highRisk: string;
      highRiskContrast: string;
      moderateRisk: string;
      moderateRiskContrast: string;
      lowRisk: string;
      lowRiskContrast: string;
    };
  }
}
