import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ExpandMore } from '@mui/icons-material';
import Button from '@mui/material/Button';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { Theme } from '@mui/material/styles/createTheme';
import { SvgIconTypeMap } from '@mui/material/SvgIcon/SvgIcon';

import { makeStyles } from '~/styles/global';

import NavMenu from './NavMenu';

interface Props {
  showLabel: boolean;
  route?: string;
  label: string;
  icon?: React.ReactNode;
  disabled?: boolean;
  children?: {
    route: string;
    label: string;
    icon?: OverridableComponent<SvgIconTypeMap<unknown, 'svg'>>;
  }[];
}

export default function NavButton({ showLabel, route, label, icon, children, disabled }: Props) {
  const navigate = useNavigate();
  const location = useLocation();
  const [anchorEl, setAnchorEl] = useState(null);
  const active = route
    ? location.pathname.includes(route)
    : !!children?.map(child => child.route).includes(location.pathname);
  const { classes } = useStyles({ active });

  const onClick = e => {
    if (route) {
      if (route === 'https://analytics.adaptivepulse.ai') {
        window.open(route, '_blank');
      } else {
        navigate(route, { replace: true });
      }
    } else {
      setAnchorEl(e.currentTarget);
    }
  };

  const onCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <ClickAwayListener onClickAway={onCloseMenu}>
        <Button
          size="small"
          className={classes.root}
          onClick={onClick}
          startIcon={!showLabel ? icon : undefined}
          endIcon={children && children.length > 0 ? <ExpandMore /> : undefined}
          sx={{
            pr: !children && !showLabel ? '0px' : 'auto',
            pl: !children && !showLabel ? '10px' : 'auto',
            minWidth: '28px',
          }}
          disabled={disabled}
        >
          {showLabel ? label : ''}
        </Button>
      </ClickAwayListener>

      {children && children.length > 0 && (
        <NavMenu
          anchorEl={anchorEl}
          menuItems={children.map(child => ({
            selected: location.pathname === child.route,
            onClick: () => {
              if (child.route === 'https://analytics.adaptivepulse.ai') {
                window.open(child.route, '_blank');
              } else {
                navigate(child.route);
              }
              onCloseMenu();
            },
            label: child.label,
            startIcon: child.icon,
          }))}
        />
      )}
    </>
  );
}

interface StyleProps {
  active: boolean;
}

const useStyles = makeStyles<StyleProps>()((theme: Theme, { active }) => ({
  root: {
    textTransform: 'none',
    fontWeight: 600,
    marginLeft: theme.spacing(2),
    borderRadius: 0,
    color: active ? theme.palette.primary.dark : theme.palette.text.primary,
    borderBottom: active ? `2px solid ${theme.palette.primary.dark}` : 'none',
  },
}));
