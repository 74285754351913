import React from 'react';
import ReactDOM from 'react-dom/client';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { IntercomProvider } from 'react-use-intercom';
import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

import App from '~/index/App';
import getTheme from '~/index/theme';

const muiCache = createCache({
  key: 'mui',
  prepend: true,
});

Sentry.init({
  dsn: import.meta.env.VITE_APP_SENTRY_DNS,
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
  enabled: import.meta.env.VITE_APP_NODE_ENV !== 'development',
});

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <Sentry.ErrorBoundary fallback={<p>An error has occurred</p>}>
    <CacheProvider value={muiCache}>
      <ThemeProvider theme={getTheme(import.meta.env.VITE_APP_THEME_MODE ?? 'light')}>
        <CssBaseline />
        <IntercomProvider appId={import.meta.env.VITE_APP_INTERCOM_APP_ID || ''} autoBoot>
          <App />
        </IntercomProvider>
      </ThemeProvider>
    </CacheProvider>
  </Sentry.ErrorBoundary>,
);
