import amplitude from 'amplitude-js';

import { AMPLITUDE_STAGING_KEY } from '~/constants/constants';
import { isDev } from '~/helpers/envCheck';
import logger from '~/helpers/logger';

export enum AMPLITUDE_EVENT {
  CLICK = 'click',
  HOVER = 'hover',

  VISIT_DATA_CONNECTIONS_PAGE = 'visitedDataConnectionsPage',
  VISIT_OVERVIEW_PAGE = 'visitedOverviewPage',
  VISIT_CUSTOMERS_PAGE = 'visitedCustomersPage',
  VISIT_DATASOURCES_PAGE = 'visitedDatasourcesPage',
  VISIT_PULSE_SCORES_PAGE = 'visitedPulseScoresPage',
  VISIT_PULSE_SCORES_OVERVIEW = 'visitedPulseScoresOverview',
  VISIT_PULSE_SCORES_CONFIGURATIONS = 'visitedPulseScoresConfigurations',

  VISIT_ANALYTICS_PAGE = 'visitedAnalyticsPage',
  VISIT_SETTINGS_PAGE = 'visitedSettings',
  VISIT_ALERTS_PAGE = 'visitedAlertsPage',
  VISIT_DATA_HUB = 'visitedDataHub',
  VISIT_DATA_HUB_QUERY = 'visitedDataHubQuery',

  VIEW_NOTIFICATION = 'viewNotification',
  READ_NOTIFICATION = 'readNotification',
  DISMISS_NOTIFICATION = 'dismissNotification',

  SUCCESS_CONNECT_DATASOURCE = 'successConnectDatasource',
  ERROR_LOGIN_FAILED = 'errorLoginFailed',
  ERROR_CONNECT_DATASOURCE_FAILED = 'errorConnectDatasourceFailed',
  TOGGLE_BILLING_PRICING = 'toggleBillingPricing',

  EXPAND_SCORE_CARD = 'expandScoreCard',
  VISIT_SCORE_CARD_PAGE = 'visitScoreCardPage',
  TOGGLE_CUSTOMER_MODEL = 'toggleCustomerModel',

  //privacy actions
  SUCCESS_CHANGE_PASSWORD = 'successChangePassword',
  ERROR_CHANGE_PASSWORD_FAILED = 'errorChangePassword',
  LOGOUT = 'logout',

  //high value actions
  ERROR_SELECT_PLAN_FAILED = 'errorSelectPlanFailed',
  SUCCESS_ADD_PAYMENT_METHOD = 'successAddPaymentMethod',
  SUCCESS_REMOVE_PAYMENT_METHOD = 'successRemovePaymentMethod',

  ERROR_CHANGE_PLAN_FAILED = 'errorChangePlanFailed',

  // Customer Data Cloud
  VISIT_DATAHUB_PAGE = 'vistDataHubPage',
  VISIT_DATAHUB_DETAILS_PAGE = 'visitDataHubDetailsPage',
  VISIT_CUSTOMER_360_PAGE = 'visitCustomer360Page',
  VISIT_WORKFLOWS_PAGE = 'visitWorkflowsPage',
}

export enum CLICKABLE {
  CHECKLIST = 'Checklist',
  NOTIFICATIONS = 'Notifications',
  VIEW_NOTIFICATION_COMPONENT = 'viewNotificationComponent',
  DISMISS_NOTIFICATION_COMPONENT = 'dismissNotificationComponent',
  CONNECT_DATASOURCE = 'ConnectDatasource',
  CONFIGURE_DATASOURCE = 'ConfigureDatasource',
  IN_PROGRESS_DATASOURCE = 'InProgressDatasource',
  MANAGE_DATASOURCES = 'manageDatasources',
  CHANGE_PASSWORD = 'ChangePassword',
  REVEAL_ORGANIZATION_SECRET = 'RevealOrganizationSecret',
  RESET_ORGANIZATION_SECRET = 'ResetOrganizationSecret',
  DELETE_OBJECT_MAPPER_CONFIGURATION = 'deleteObjectMapperConfiguration',
  SELECT_PLAN = 'SelectPlan',
  CHANGE_PLAN = 'ChangePlan',
  EDIT_BILLING = 'EditBilling',
  SUBSCRIBE_PLAN = 'SubscribePlan',
  RESET_FIELDS_OBJECT_MAPPER = 'ResetFieldsObjectMapper',
  ADD_FIELD_OBJECT_MAPPER = 'AddFieldObjectMapper',
  CANCEL_ADD_FIELD_OBJECT_MAPPER = 'CancelAddFieldObjectMapper',
  SAVE_OBJECT_MAPPER = 'SaveObjectMapper',
  RESET_SECRET_KEY = 'resetSecretKey',
  REFRESH_PRIVATE_KEY = 'refreshPrivateKey',
  SYNC_DATA_CARD = 'syncDataCard',
  VIEW_SCORE_CARD = 'viewScoreCard',
  VIEW_ALL_COMBINED_SCORE_CARDS = 'viewAllCombinedScoreCards',
  CANCEL_LINK_CONFIGURATION = 'cancelLinkConfiguration',
  CONFIRM_LINK_CONFIGURATION = 'confirmLinkConfiguration',
  CANCEL_JOIN_CONFIGURATION = 'cancelJoinConfiguration',
  SET_JOIN_CONFIGURATION = 'setJoinConfiguration',
  SAVE_CONFIGURATION_COMPONENT = 'saveConfigurationComponent',
  START_MAPPING = 'startMapping',
  START_OBJECT_MAPPER_INGEST = 'startObjectMapperIngest',
  SUBMIT_UPDATE_PROFILE_WIDGET = 'submitUpdateProfileWidget',
  CONTINUE_UPDATE_PROFILE_WIDGET = 'continueUpdateProfileWidget',
  FINISH_HOW_TO_WIDGET = 'finishHowToWidget',
  CONTINUE_HOW_TO_WIDGET = 'continueHowToWidget',
  SUBMIT_CREATE_ORG = 'submitCreateOrg',
  CONTINUE_CREATE_ORG = 'continueCreateOrg',
  JOIN_ORG = 'continueJoinOrg',
  CREATE_NBA_TRIGGER = 'createNBATrigger',
  CONFIRM_CREATE_NBA_TRIGGER = 'confirmCreateNBATrigger',
  CANCEL_CREATE_NBA_TRIGGER = 'cancelCreateNBATrigger',
  CREATE_NBA = 'createNBA',
  VIEW_DATA_CONNECTIONS = 'viewDataConnections',
  VIEW_CUSTOMERS_TABLE = 'viewCustomersTable',
  CANCEL_EDIT_NOTE = 'cancelEditNote',
  SAVE_EDITED_NOTE = 'saveEditedNote',
  REMOVE_DATA_HUB_QUERY = 'removeDataHubQuery',
  ADD_QUERY_TO_FEATURES = 'addQueryToFeatures',
  SIGN_UP_BUTTON = 'signUpButton',

  DELETE_WORKFLOW = 'DeleteWorkflow',
  EDIT_WORKFLOW = 'EditWorkflow',
  CREATE_WORKFLOW = 'createWorkFlow',
  CANCEL_WORKFLOW_TRIGGER_DIALOGUE = 'cancelWorkFlowTriggerDialogue',
  ADD_WORKFLOW_TRIGGER_DIALOGUE = 'addWorkFlowTriggerDialogue',
  CANCEL_WORKFLOW_SOURCE_DIALOGUE = 'cancelWorkFlowSourceDialogue',
  ADD_WORKFLOW_SOURCE_DIALOGUE = 'addWorkFlowSourceDialogue',
  CANCEL_WORKFLOW_ACTION_DIALOGUE = 'cancelWorkflowActionDialogue',
  ADD_WORKFLOW_ACTION_DIALOGUE = 'addWorkFlowActionDialog',
  DELETE_WORKFLOW_PART = 'deleteWorkFlowPart',
  UPDATE_WORKFLOW = 'updateWorkFlow',
  ADD_TRIGGER_TO_WORKFLOW = 'AddTriggerToWorkFlow',
  ADD_SOURCE_TO_WORKFLOW = 'addSourceToWorkFlow',
  ADD_ACTION_TO_WORKFLOW = 'addActionToWorkFlow',
  EDIT_AI_MODEL = 'EditAiModel',
  KEY_PHRASE = 'KeyPhrase',
  CHANGE_SENTIMENT = 'ChangeSentiment',
  ENTITY = 'Entity',
  SAVE_PROFILE = 'SaveProfile',
  CONTINUE_ADD_API_TOKEN = 'continueAddAPIToken',
  GO_HOME_AFTER_PAYMENT_SUCCESS = 'goHomeAfterPaymentSuccess',

  REVOKE_TEAM_INVITE = 'revokeTeamInvite',
  INVITE_TEAM = 'inviteTeam',
  CANCEL_INVITE_TEAM = 'cancelInviteTeam',
  SENDING_TEAM_INVITE = 'sendingTeamInvite',

  CONNECT_CSV = 'ConnectCsv',
  DISCONNECT_CSV = 'DisconnectCsv',
  CLOSE_INTEGRATE_CSV_DIALOGUE = 'closeIntegrateCSVDialogue',
  CONTINUE_INTEGRATE_CSV_DIALOGUE = 'continueIntegrateCSVDialogue',
  INTEGRATE_CSV = 'integrateCSV',
  REMOVE_CUSTOM_CSV = 'removeCustomCSV',
  UPDATE_CUSTOM_CSV = 'updateCustomCSV',
  SELECT_GLOBAL_MODEL = 'SelectGlobalModel',
  VIEW_AI_MODEL = 'ViewAiModel',
  DELETE_AI_MODEL = 'DeleteAiModel',
  CREATE_AI_MODEL = 'CreateAiModel',
  REFRESH_DATASOURCE = 'RefreshDatasource',
  OPEN_APP_INTEGRATION_SETUP = 'OpenAppIntegrationSetup',

  ADD_NOTE = 'AddNote',
  ENABLE_TEAM_FEATURE = 'EnableTeamFeature',
  ADD_FIRST_SQL_CONNECTION = 'AddFirstSqlConnection',
  SETUP_SQL_TABLE = 'SetupSqlTable',
  SETUP_SQL_OBJECT = 'SetupSqlObject',
  REMOVE_SQL_OBJECT = 'RemoveSqlObject',
  REMOVE_SQL_TABLE = 'RemoveSqlTable',
  PREVIEW_SQL_TABLE = 'previewSQLTable',
  SAVE_SQL_TABLE_CONFIGURATION = 'saveSQLTableConfiguration',
  CONFIRM_DELETE_NOTE = 'ConfirmDeleteNote',
  CANCEL_DELETE_NOTE = 'CancelDeleteNote',
  CANCEL_REVEAL_SECRET = 'CancelRevealSecret',
  REVEAL_SECRET = 'RevealSecret',
  SAVE_SUBDOMAIN = 'saveSubdomain',
  REVEAL_PASSWORD = 'revealPassword',
  ADD_JOIN_TO_SQL_TABLE = 'addJoinToSQLTable',
  FINISH_SQL_DATABASE_SETTINGS = 'finishSQLDatabaseSettings',
  CONTINUE_SQL_DATABASE_SETTINGS = 'continueSQLDatabaseSettings',
  GO_BACK_SQL_DATABASE = 'goBackSQLDatabase',
  TEST_SQL_CONNECTION = 'testSQLConnection',
  SETUP_IMPORTS_SQL_CONNECTION = 'setupImportsSQLConnection',
  DO_SQL_CONNECTIONS_LATER = 'doSQLConnectionsLater',
  ADD_SQL_CONNECTION = 'addSQLConnection',
  MODIFY_SQL_CONNECTION = 'modifySQLConnection',
  REMOVE_SQL_CONNECTION = 'removeSQLConnection',
  ADD_NEW_SQL_DATABASE = 'addNewSQLDatabase',
  CLEAR_SQL_EDITOR = 'clearSQLEditor',
  RUN_SQL_EDITOR = 'runSQLEditor',

  CREATE_FIRST_GOOGLE_ALERT = 'createFirstGoogleAlert',
  CREATE_GOOGLE_ALERT = 'createGoogleAlert',

  CONFIGURE_FIRST_PULSE_SCORE_CONFIGURATION = 'configureFirstPulseScoreConfiguration',
  VIEW_PULSE_SCORE_CONFIGURATION = 'viewPulseScoreConfiguration',
  CANCEL_PULSE_SCORE_MODEL = 'cancelPulseScoreModel',
  SAVE_PULSE_SCORE_CONFIGURATION = 'savePulseScoreConfiguration',
  PULSE_SCORE_CONFIGURATION_SETTINGS = 'pulseScoreConfigurationSettings',
  SHOW_EXAMPLE_PULSE_SCORE_CONFIGURATION = 'showExamplePulseScoreConfiguration',
  GO_BACK_TO_ALL_PULSE_SCORE_CONFIGURATIONS = 'goBackToAllPulseScoreConfigurations',
  GO_TO_OBJECT_MAPPER_PULSE_SCORE_CONFIGURATIONS = 'goToObjectMapperPulseScoreConfigurations',
  SAVE_MODEL_MODAL = 'saveModelModal',
  NEXT_STEP_MODEL_MODAL = 'nextStepModelModal',
  PREVIOUS_STEP_MODEL_MODAL = 'previousStepModelModal',
  LIMIT_REACHED_CLOSE_MODEL_MODAL = 'limitReachedCloseModelModal',
  LIMIT_REACHED_CANCEL_MODEL_MODAL = 'limitReachedCancelModelModal',
  CONFIRM_CANCEL_NEW_MODEL_MODAL = 'confirmCancelNewModelModal',
  CONFIRM_DELETE_AI_MODELS = 'confirmDeleteAIModels',
  CANCEL_DELETE_AI_MODELS = 'cancelDeleteAIModels',
  CREATE_PULSE_SCORE_MODEL = 'createPulseScoreModel',

  CONFIRM_CHANGE_PASSWORD = 'ConfirmChangePassword',

  PAYMENT_CANCEL_RETURN_HOME = 'PaymentCancelReturnHome',
  BEST_PERFORMING = 'BestPerforming',
  WORST_PERFORMING = 'WorstPerforming',

  OPEN_DATASOURCE_INSTRUCTIONS = 'openDatasourceInstructions',
  OPEN_INTEGRATION_INSTRUCTIONS = 'openAppIntegrationInstructions',
  OPEN_OBJECT_MAPPER_INSTRUCTIONS = 'openObjectMapperInstructions',
  OPEN_PULSE_SCORES_INSTRUCTIONS = 'openPulseScoresInstructions',
  OPEN_ONBOARDING_INSTRUCTIONS = 'openOnboardingInstructions',
  OPEN_CONNECT_INSTRUCTIONS = 'openConnectInstructions',

  // Customer Data Cloud
  CLICK_NEW_DATA_HUB = 'clickNewDataHub',
}

export const initAmplitude = () => {
  let amplitudeKey = ''; //development
  if (import.meta.env.VITE_APP_NODE_ENV === 'production')
    amplitudeKey = import.meta.env.VITE_APP_AMPLITUDE ?? AMPLITUDE_STAGING_KEY;

  amplitude.getInstance().init(amplitudeKey);
};

export const setAmplitudeUserDevice = (installationToken: string) => {
  if (isDev()) return;
  amplitude.getInstance().setDeviceId(installationToken);
};

export const setAmplitudeUserId = (userId: string | null) => {
  if (isDev()) return;
  amplitude.getInstance().setUserId(userId);
};

export const setAmplitudeUserProperties = (properties: any) => {
  if (isDev()) return;
  amplitude.getInstance().setUserProperties(properties);
};

export const sendAmplitudeData = (eventType: AMPLITUDE_EVENT, eventProperties?: any) => {
  logger.info(`[Amplitude] ${eventType}`, eventProperties);
  if (!isDev()) {
    amplitude.getInstance().logEvent(eventType, eventProperties);
  }
};
