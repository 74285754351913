/**
 * Asynchronously loads the component for WorkflowsPage
 */

import { lazyLoad } from '~/helpers/loadable';

export const WorkflowsPage = lazyLoad(
  () => import('./index'),
  module => module.WorkflowsPage,
);
