import React, { useState } from 'react';
import Alert, { AlertColor } from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';

import { createGenericContext } from '~/helpers/createGenericContext';

const NOTIFICATION_TRANSITION_DURATION = 250;

interface IToastShowMessageProps {
  message: string;
}

interface IToastContextProps {
  showSuccess: (message: string, options?: IToastShowMessageProps) => any;
  showError: (message: string, options?: IToastShowMessageProps) => any;
  showWarning: (message: string, options?: IToastShowMessageProps) => any;
  showInfo: (message: string, options?: IToastShowMessageProps) => any;
}

export const [useToast, ToastContextProvider] = createGenericContext<IToastContextProps>();

export default function ToastContext({ children }) {
  const [level, setLevel] = useState<AlertColor>('info');
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');

  function handleClose(e, reason?: string) {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);

    // this prevents weird flickering when text suddenly disappears while animating the close
    window.setTimeout(() => {
      setMessage('');
    }, NOTIFICATION_TRANSITION_DURATION);
  }

  function showSuccess(message: string) {
    if (!message) return;
    setMessage(message);
    setLevel('success');
    setOpen(true);
  }

  function showError(message: string) {
    if (!message) return;
    setMessage(message);
    setLevel('error');
    setOpen(true);
  }

  function showWarning(message: string) {
    if (!message) return;
    setMessage(message);
    setLevel('warning');
    setOpen(true);
  }

  function showInfo(message: string) {
    if (!message) return;
    setMessage(message);
    setLevel('info');
    setOpen(true);
  }

  const context: IToastContextProps = {
    showSuccess,
    showError,
    showWarning,
    showInfo,
  };
  return (
    <ToastContextProvider value={context}>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={open}
        autoHideDuration={6000}
        transitionDuration={NOTIFICATION_TRANSITION_DURATION}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity={level}>
          {message}
        </Alert>
      </Snackbar>
      {children}
    </ToastContextProvider>
  );
}
